import React, { useEffect } from "react";
import { queryHandler } from "../foundation/query.handler";
import { scriptHandler } from "../foundation/script.handler";
import SEO from "../components/SEO";
import { Footer } from "../tailwind/Footer";
import { Navbar } from "../tailwind";
import { faqHandler } from "../tailwind/faqs/faq.handler";

export default ({
                  location,
                  pageContext: {
                    product: { name, phone, footer, clickId, articles, slug },
                  },
                }) => {
  useEffect(() => {
    scriptHandler.accordion();
  }, []);

  useEffect(() => {
    const { utm_source, zip, ...restQueryParams } = queryHandler(location);
    scriptHandler.mediaAlpha({
      placement_id: clickId,
      utm_source,
      zip,
      type: "ad_unit",
      ...restQueryParams,
    });
  }, [clickId]);

  return (
    <div style={{ backgroundColor: '#F9FBFD'}}>
      <SEO title={name} />
      <Navbar phoneNumber={phone}/>
      <div className="px-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg px-8">
        <div className="mt-4 p-4 rounded shadow-xl border">
          <div className="text-center mt-4">
            <h1 className="mb-1 text-2xl font-extrabold leading-none sm:text-3xl">Get great {name.toLowerCase()} insurance rates!</h1>
            <p className="text-base text-gray-700 sm:text-base md:text-lg lg:text-base xl:text-lg">
              Click on a few of the companies below to get your rates.
            </p>
          </div>

          <div id="wrapper">
            <div id="wrapper-content">
              <div id="media-alpha"/>
            </div>
          </div>

        </div>
      </div>
      {faqHandler(slug || "car").rates()}
      <Footer />
    </div>
  );
};
