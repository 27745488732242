import { MEDIA_ALPHA_ENDPOINT } from "./url.constants";

const loadScript = src => {
  return new Promise((resolve, reject) => {
    const head = document.head || document.getElementsByTagName("head")[0];
    const script = window.document.createElement("script");
    if (src) script.src = src;
    script.type = "text/javascript";
    script.async = false;
    script.defer = false;
    script.onload = () => {
      console.log(src + " script is loaded!");
    };
    script.addEventListener("load", () => resolve(script), false);
    script.addEventListener("load", () => reject(script), false);
    head.appendChild(script);
  });
};

export const scriptHandler = {
  mediaAlpha: ({ utm_source, placement_id, zip, type, clientRoute, ...restData }) => {
    loadScript(`${MEDIA_ALPHA_ENDPOINT}/js/serve.js`).then(() => {
      if (typeof window !== "undefined") {
        window["MediaAlphaExchange"] = {
          type,
          placement_id,
          version: 17,
          ua_class: "auto",
          sub_1: clientRoute,
          sub_2: utm_source,
          data: {
            zip: zip,
            ...restData
          },
        };
        window.MediaAlphaExchange__load("media-alpha");
      }
    });
  },
  accordion: () => {
    loadScript("/accordion.js");
  },
};
